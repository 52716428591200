<template>
  <section
    ref="sectionWrapper"
    :class="{
      'section-wrapper': true,
      'pointer-events-none': !dimmed,
      'fade-out': !dimmed,
    }"
  >
    <div class="scroll-section section-intro">
      <div class="section-title">
        <img
          src="@/assets/images/exhibition-1-title@2x.png"
          class="title"
          alt="游歷時間 exploratorium"
          width="100%"
          height="auto"
        />
      </div>
      <div class="section-panel-text">
        <p>
          在同一片天空下，看滄海桑田的變幻無常；在同一片土地上，看古往今來文化碰撞的火花。在我們如今稱之為「中亞」的土地上，曾經有著許多民族的爭鬥與融合——波斯、希臘、突厥、阿拉伯、蒙古、俄羅斯⋯⋯這片土地也受過不同宗教的洗禮——鎖羅亞斯德教（祆教）、佛教、伊斯蘭教⋯⋯
        </p>
        <p>
          文明，源自人如何看待自己與自然（天地）、與他者、以及與自己的關係。「中亞」土地所承載的故事，也是人類數千年來不斷重複的歷史的縮影。此數碼裝置通過變幻的氛圍、抽象的線條、字型的設計等來呈現創作團隊眼中複雜的「中亞」。數碼技術讓我們在裝置中感受到非單一線性的歷史進程，開啟我們探問「何謂中亞」之旅。
        </p>
        <p>
          這趟「游歷時間」的旅程——「游」強調的是「穿越」（traversing）過程裡的飄蕩不定以及與河流的關係。行行重行行⋯⋯
        </p>
      </div>
      <div class="btn-controls-vertical">
        <div
          class="btn-next btn-vertical btn-next-vertical position-relative"
          @click="gotoMenu"
        >
          <inline-svg class="arrow-lg-gallery-1" :src="require(`@/assets/images/arrow-lg.svg`)" />
        </div>
      </div>
      <div ref="spacer" class="section-content-spacer gallery1-spacer"></div>
    </div>
    <div class="gallery-1-bg-container"></div>
  </section>
</template>

<script>
import { gsap, Power2 } from "gsap";
export default {
  data() {
    return {
      dimmed: true,
      sectionsName: [0, 1, 2, 3, 4],
    };
  },
  mounted() {},
  methods: {
    gotoMenu() {
      const vm = this;
      const tl = new gsap.timeline();
      tl.to(this.$refs.sectionWrapper, {
        duration: 2,
        opacity: 0,
        transform: "translateY(-100vh)",
        display: "none",
        ease: Power2.easeInOut,
        onComplete: function() {
          if (vm.$route.name != "Maps") {
            vm.$router.push({ name: "Maps" });
          }
        },
      });
    },
  },
  computed: {
    currentSection() {
      return this.$store.state.gallery1.currentSection;
    },
    showArrows() {
      return this.$store.state.gallery1.showArrows;
    },
  },
};
</script>

<style lang="scss">
</style>
